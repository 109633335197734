import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import LoadingPage from './LoadingPage';
import MessageBox from './MessageBox';
import { zcDateFormat } from '../utility/zcFormatDate';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Image,
  Select,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

const OrderDetails = () => {
  const params = useParams();
  const remoteApi = '/api';
  const [orderData, setOrderData] = useState({});
  const [orderId, setOrderId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isOrderCompleted, setIsOrderCompleted] = useState(true);
  const [itemImages, setItemImages] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const isSmallScreen = useBreakpointValue({ base: true, md: true, lg: false });

  const fetchProductId = async (sku) => {
    try {
      const response = await axios.get(
        `${remoteApi}/products/search?sku=${encodeURIComponent(sku)}`
      );
      if (response?.data?.message === 'success') {
        return Object.keys(response?.data?.data)[0];
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const fetchImageUrl = async (productId) => {
    try {
      const response = await axios.get(
        `${remoteApi}/products/images/${productId}`
      );

      if (response?.data?.message === 'success') {
        return response?.data?.data[0]?.url;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    setOrderId(params.id);
  }, [params.id]);

  useEffect(() => {
    const getOrderData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${remoteApi}/order/${orderId}`);
        if (response?.data?.message === 'success') {
          setOrderData(response?.data?.data);
          setIsOrderCompleted(
            response?.data?.data?.status === 'complete' ? true : false
          );

          const _items = [...response?.data?.data?.order_items];
          _items.map(async (item) => {
            const productId = await fetchProductId(item?.sku);
            if (!productId) return;

            const imageUrl = await fetchImageUrl(productId);
            setItemImages((prevData) => ({
              ...prevData,
              [item.item_id]: { imageUrl },
            }));
          });
        } else {
          setIsError(true);
        }
      } catch (err) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (orderId) getOrderData();
  }, [orderId]);

  const returAndGarantie = (type, orderData) => {
    const productsSku = selectedItems
      .map((item) => `${item.sku} - ${item.quantity} buc.`)
      .join(',');

    const shippingAddress = orderData?.addresses?.find(
      (_address) => _address?.address_type === 'billing'
    );

    const Nume_Prenume = `${shippingAddress?.firstname ?? ''} ${
      shippingAddress?.lastname ?? ''
    }`;
    let Factura_este_pe = 'Persoana fizica';
    if (shippingAddress?.company !== null) {
      Factura_este_pe = 'Persoana juridica';
    }

    const completeComments = orderData?.order_comments?.filter(
      (comment) => comment.status === 'complete'
    );
    const minDate = new Date(
      Math.min(
        ...completeComments.map((comment) => new Date(comment.created_at))
      )
    );

    const param = `Tip_Solicitare=${type}&Numar_comanda_factura=${
      orderData?.entity_id
    }&Cod_produs=${productsSku}&Factura_este_pe=${Factura_este_pe}&Nume_Prenume=${Nume_Prenume}&Nume_firma=${
      shippingAddress?.company ?? ''
    }&Email=${shippingAddress?.email ?? ''}&Numar_telefon=${
      shippingAddress?.telephone ?? ''
    }&Sales_Order_Date=${zcDateFormat(minDate)}`;

    window.open(
      `https://creatorapp.zohopublic.com/spyshop/order-management-4-0/form-perma/Ticket_form/W66tPVJfBKqugafS2qn6VNOHHSMmQ5xnNtG8JDVYw0P6XuxF9fFkFUs49r3gzvJGqCU4d0JnmRdZ1RwfhNPhxFzhZYR5H1au21Qs?${param}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  const calculateTotalCost = () => {
    return orderData?.order_items?.reduce((acc, item) => {
      const subtotal = Number(item?.row_total_incl_tax?.slice(0, -2) ?? 0) || 0;
      return acc + subtotal;
    }, 0);
  };

  const handleSelectItem = (item) => {
    setSelectedItems((prev) =>
      prev.some((i) => i.id === item.item_id)
        ? prev.filter((i) => i.id !== item.item_id)
        : [
            ...prev,
            {
              id: item.item_id,
              name: item.name,
              sku: item.sku,
              quantity: Number(item.qty_ordered?.split('.')[0]) || 1,
            },
          ]
    );
  };

  const handleSelectAll = () => {
    if (selectedItems.length === orderData.order_items.length) {
      setSelectedItems([]);
    } else {
      const allItems = orderData.order_items.map((item) => ({
        id: item.item_id,
        sku: item.sku,
        quantity:
          Number(item?.qty_ordered?.split('.')[0]) -
            Number(item?.qty_refunded?.split('.')[0]) || 1,
      }));
      setSelectedItems(allItems);
    }
  };

  const handleQuantityChange = (itemId, value) => {
    setSelectedItems((prev) =>
      prev.map((item) =>
        item.id === itemId ? { ...item, quantity: parseInt(value, 10) } : item
      )
    );
  };

  if (isLoading) return <LoadingPage />;
  if (isError)
    return (
      <MessageBox
        primaryText="Oops! Ceva nu a mers ok."
        SecondaryText="Nu putem accesa datele comenzii."
      />
    );

  const totalCost = calculateTotalCost();

  return (
    <Box mt="3rem" p={5} maxWidth="1100px" mx="auto">
      <Heading fontWeight="normal" size="xl" mb={5}>
        Detalii comanda nr. {orderData?.entity_id}
      </Heading>

      <Flex justify="space-between" align="center" mb={5}>
        <Button
          borderRadius="sm"
          size="xs"
          colorScheme="orange"
          variant="solid"
        >
          {orderData?.status}
        </Button>

        <Text fontWeight="bold" fontSize="lg">
          Cost total: {totalCost.toFixed(2)} lei
        </Text>
      </Flex>

      <Text fontSize="sm">
        Selectează produsele pentru Retur/Garanție și cantitatea dorită
      </Text>

      {selectedItems.length > 0 && (
        <Flex align="right" justify="right" gap={5} mt="5">
          <Button
            minWidth={20}
            borderRadius="sm"
            size="xs"
            colorScheme="blue"
            onClick={() => returAndGarantie('Retur', orderData)}
          >
            Retur
          </Button>
          <Button
            minWidth={20}
            borderRadius="sm"
            size="xs"
            colorScheme="orange"
            onClick={() => returAndGarantie('Garantie', orderData)}
          >
            Garantie
          </Button>
        </Flex>
      )}

      {isSmallScreen && (
        <Checkbox
          mt="5"
          ml="2"
          isChecked={selectedItems.length === orderData?.order_items?.length}
          onChange={handleSelectAll}
        >
          Selectează toate produsele
        </Checkbox>
      )}

      {!isOrderCompleted ? (
        <MessageBox
          primaryText="Aceasta comanda nu a fost inca livrata."
          SecondaryText="Pentru a putea solicita un retur sau pentru o cerere de garantie comanda trebuie sa fie livrata."
        />
      ) : isSmallScreen ? (
        <VStack spacing={5} mt={5}>
          {orderData?.order_items?.map((item) => {
            const isSelected = selectedItems.some((i) => i.id === item.item_id);
            const selectedItem = selectedItems.find(
              (i) => i.id === item.item_id
            );
            const defaultQuantity =
              Number(item?.qty_ordered?.split('.')[0]) -
              Number(item?.qty_refunded?.split('.')[0]);

            return (
              <Box
                key={item.item_id}
                borderWidth="1px"
                borderRadius="lg"
                p={5}
                w="full"
                boxShadow="md"
              >
                <Flex align="center" mb={4}>
                  <Checkbox
                    isChecked={isSelected}
                    onChange={() => handleSelectItem(item)}
                    mr={4}
                  />
                  <Image
                    src={itemImages[item?.item_id]?.imageUrl}
                    alt={item?.name}
                    boxSize="100px"
                    objectFit="cover"
                    mr={4}
                  />
                  <Box>
                    <Text fontWeight="bold">{item?.name}</Text>
                    <Text>Cod produs: {item?.sku}</Text>
                    <Text>
                      Cantitate:{' '}
                      {Number(item?.qty_ordered?.split('.')[0]) -
                        Number(item?.qty_refunded?.split('.')[0])}{' '}
                      buc.
                    </Text>
                    <Text>
                      Subtotal: {item?.row_total_incl_tax?.slice(0, -2) ?? '0'}{' '}
                      lei
                    </Text>

                    <Select
                      mt={2}
                      value={
                        isSelected
                          ? selectedItem?.quantity || ''
                          : defaultQuantity
                      }
                      onChange={(e) =>
                        handleQuantityChange(
                          item.item_id,
                          Number(e.target.value)
                        )
                      }
                      disabled={!isSelected}
                    >
                      {Array.from(
                        {
                          length: defaultQuantity,
                        },
                        (_, i) => i + 1
                      ).map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </Flex>
              </Box>
            );
          })}
        </VStack>
      ) : (
        <Box
          border="1px solid #E2E8F0"
          borderRadius="md"
          mt={5}
          overflow="hidden"
        >
          <Table variant="simple">
            <Thead h={20} bg="gray.100">
              <Tr>
                <Th>
                  <Checkbox
                    style={{ fontSize: '10px' }}
                    isChecked={
                      selectedItems.length === orderData?.order_items?.length
                    }
                    onChange={handleSelectAll}
                  />

                  <Text fontSize="10px"></Text>
                </Th>
                <Th>Produs</Th>
                <Th></Th>
                <Th>Cod produs</Th>
                <Th>Cantitate</Th>
                <Th>Subtotal</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orderData?.order_items?.map((item) => {
                const isSelected = selectedItems.some(
                  (i) => i.id === item.item_id
                );
                const selectedItem = selectedItems.find(
                  (i) => i.id === item.item_id
                );

                const defaultQuantity =
                  Number(item?.qty_ordered?.split('.')[0]) -
                  Number(item?.qty_refunded?.split('.')[0]);

                return (
                  <Tr key={item?.item_id}>
                    <Td>
                      <Checkbox
                        isChecked={isSelected}
                        onChange={() => handleSelectItem(item)}
                      />
                    </Td>
                    <Td>
                      <Image
                        src={itemImages[item?.item_id]?.imageUrl}
                        alt={'-'}
                        boxSize="125px"
                        mr={3}
                        minW={'125px'}
                      />
                    </Td>
                    <Td>
                      <Text>{item?.name}</Text>
                    </Td>
                    <Td minWidth={150}>
                      <Text fontWeight="semibold">{item?.sku}</Text>
                    </Td>
                    <Td minWidth={100}>
                      <Select
                        value={
                          isSelected
                            ? selectedItem?.quantity || ''
                            : defaultQuantity
                        }
                        onChange={(e) =>
                          handleQuantityChange(
                            item.item_id,
                            Number(e.target.value)
                          )
                        }
                        disabled={!isSelected}
                      >
                        {Array.from(
                          {
                            length:
                              Number(item?.qty_ordered?.split('.')[0]) -
                                Number(item?.qty_refunded?.split('.')[0]) || 1,
                          },
                          (_, i) => i + 1
                        ).map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </Select>
                      buc.
                    </Td>
                    <Td minWidth={150}>
                      <Text align="right">
                        {item?.row_total_incl_tax?.slice(0, -2)} lei
                      </Text>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default OrderDetails;
